.cloudHead {
  height: 550px;
  overflow: hidden;
  background: #46bbdb;
  background: -moz-linear-gradient(top, #2e89d3 0%, #46bbdb 40%);
  background: -webkit-linear-gradient(top, #2e89d3 0%, #46bbdb 40%);
  background: linear-gradient(to bottom, #2e89d3 0%, #46bbdb 40%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#2e89d3, endColorstr=#46bbdb, GradientType=0);
}
.cloudHead .layer {
  width: 150%;
  left: -50% !important;
}
.cloudHead .parallax-clouds {
  height: 350px;
  width: 150%;
}
.cloudHead .parallax-clouds.cloud1 {
  background: url("../images/bg_cloud-layer1.png") repeat-x bottom;
}
.cloudHead .parallax-clouds.cloud2 {
  background: url("../images/bg_cloud-layer2.png") repeat-x bottom;
}
.cloudHead .parallax-clouds.cloud3 {
  background: url("../images/bg_cloud-layer3.png") repeat-x bottom;
}
.cloudHead .parallax-clouds.mobile {
  background: url("../images/bg_clouds_mobile2.png") #46bbdb repeat-x bottom;
  width: 100%;
  height: 149px;
}
.cloudHead .btnLogin {
  position: absolute;
  top: 20px;
  right: 20px;
}
.cloudHead .btnLogin:hover,
.cloudHead .btnLogin:focus,
.cloudHead .btnLogin:active {
  color: #46bbdb !important;
}
.cloudHead.mobileHead {
  height: 350px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.googleSearch {
  padding-top: 40px;
  box-sizing: border-box;
}
.googleSearch form.gsc-search-box {
  width: 40% !important;
  margin: 0 auto 0 auto !important;
}
.googleSearch .gsc-search-box {
  text-align: center;
}
.googleSearch .gsc-input-box {
  border: 0 !important;
  background: transparent !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.googleSearch .gsc-search-box input.gsc-input {
  height: 40px !important;
  border: 5px solid rgba(144, 213, 223, 0.3) !important;
  outline: none !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 1px 0 10px 0 rgba(50, 50, 50, 0.25);
  -moz-box-shadow: 1px 0 10px 0 rgba(50, 50, 50, 0.25);
  box-shadow: 1px 0 10px 0 rgba(50, 50, 50, 0.25);
  font-size: 16px;
  padding: 0px 5px !important;
  color: #333 !important;
}
.googleSearch .gsc-search-box button.gsc-search-button {
  background: #2e89d3 !important;
  color: white !important;
  border: 5px solid rgba(221, 242, 245, 0.8) !important;
  -webkit-box-shadow: 1px 0 10px 0 rgba(50, 50, 50, 0.25);
  -moz-box-shadow: 1px 0 10px 0 rgba(50, 50, 50, 0.25);
  box-shadow: 1px 0 10px 0 rgba(50, 50, 50, 0.25);
  outline: none !important;
  cursor: pointer;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
  margin-top: 3px;
  height: 41px;
}
.googleSearch .gsc-search-box button.gsc-search-button svg {
  width: 17px;
  height: 17px;
}
.googleSearch table.gsc-search-box td.gsc-input {
  padding: 0 !important;
}
.googleSearch td.gsc-clear-button {
  display: none;
}
.googleSearch .gsc-search-box .gsc-input > input:hover,
.googleSearch .gsc-input-box-hover {
  border: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.googleSearch .gsc-search-box .gsc-input > input:focus,
.googleSearch .gsc-input-box-focus {
  border: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.googleSearch .gsst_a .gscb_a,
.googleSearch .gsib_b {
  display: none !important;
}
