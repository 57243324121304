.cloudMenuWrap {
  max-width: 1100px;
  margin: 0 auto;
  height: 70px;
  background-color: #fff;
}
.cloudMenuWrap .cloudMenuInner {
  margin: 10px auto 0;
}
.cloudMenuWrap .cloudMenuInner .right {
  float: right;
}
.cloudMenuWrap .cloudMenuInner .right .dropdown.icon {
  padding: 1em !important;
}
.cloudMenuWrap .cloudMenuInner .right .input.icon {
  width: 150px;
}
.cloudMenuWrap .cloudMenuInner .right .menuTraget .dropdown.icon {
  padding-bottom: 0.78571429em !important;
  padding-top: 0.78571429em !important;
  padding-left: 4.07142857em !important;
  padding-right: 1.5em !important;
}
.cloudMenuWrap .cloudMenuInner .left {
  float: left;
}
.cloudMenuWrap.mobile {
  max-width: 300px;
}
.circle-menu-button {
  border: none;
  background: none;
  color: white;
  text-align: center;
  font-size: 1.8em;
  padding-bottom: 1em;
  height: 3.5em;
  width: 3.5em;
  background-color: #fff;
  position: fixed;
  left: 50%;
  margin-left: -1.75em;
  border-radius: 50%;
  cursor: pointer;
  z-index: 21;
  bottom: -45px;
  outline: none;
  box-shadow: inset 0 0 30px #fff, inset 1px 0 50px #46bbdb, inset -30px 0 60px #2e89d3, inset 1px 0 300px #46bbdb, inset -30px 0 300px #2e89d3, 0 0 1px #fff, -1px 0 7px #46bbdb, 1px 0 1px #2e89d3;
}
.circle-menu-button:hover {
  color: #333;
}
.circle-menu-wrap {
  width: 20em;
  height: 20em;
  position: fixed;
  bottom: -10em;
  left: 50%;
  margin-left: -10em;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
  outline: none;
  box-shadow: 0px 0px 6px 0px rgba(51, 51, 51, 0.7);
  -moz-box-shadow: 0px 0px 6px 0px rgba(51, 51, 51, 0.7);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(51, 51, 51, 0.7);
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  -moz-transform: scale(0.1);
  transform: scale(0.1);
  pointer-events: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.circle-menu-wrap .circle-menu,
.circle-menu-wrap .circle-menu-small {
  position: relative;
  padding: 0;
  margin: 0;
  width: 20em;
  height: 20em;
  border-radius: 50%;
  list-style: none;
  overflow: hidden;
  transform: rotate(-18deg);
}
.circle-menu-wrap .circle-menu-small {
  width: 13em;
  height: 13em;
  position: absolute;
  z-index: 10;
  top: 56px;
  left: 56px;
}
.circle-menu-wrap li {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0% 100%;
  cursor: pointer;
}
.circle-menu-wrap li:hover {
  background-color: white;
}
.circle-menu-wrap li:hover .text {
  color: #333;
}
.circle-menu-wrap li[disabled] {
  pointer-events: none;
}
.circle-menu-wrap li[disabled] .text {
  color: rgba(40, 40, 40, 0.3);
}
.circle-menu-wrap .text {
  position: absolute;
  left: -100%;
  width: 200%;
  height: 200%;
  text-align: center;
  -webkit-transform: skewY(54deg) rotate(15deg);
  -ms-transform: skewY(54deg) rotate(15deg);
  transform: skewY(54deg) rotate(15deg);
  padding-top: 20px;
  padding-left: 15px;
  color: white;
}
.circle-menu-wrap li:first-child {
  -webkit-transform: rotate(0deg) skewY(-54deg);
  -ms-transform: rotate(0deg) skewY(-54deg);
  transform: rotate(0deg) skewY(-54deg);
}
.circle-menu-wrap li:nth-child(2) {
  -webkit-transform: rotate(36deg) skewY(-54deg);
  -ms-transform: rotate(36deg) skewY(-54deg);
  transform: rotate(36deg) skewY(-54deg);
}
.circle-menu-wrap li:nth-child(3) {
  -webkit-transform: rotate(72deg) skewY(-54deg);
  -ms-transform: rotate(72deg) skewY(-54deg);
  transform: rotate(72deg) skewY(-54deg);
}
.circle-menu-wrap li:nth-child(4) {
  -webkit-transform: rotate(108deg) skewY(-54deg);
  -ms-transform: rotate(108deg) skewY(-54deg);
  transform: rotate(108deg) skewY(-54deg);
}
.circle-menu-wrap li:nth-child(5) {
  -webkit-transform: rotate(144deg) skewY(-54deg);
  -ms-transform: rotate(144deg) skewY(-54deg);
  transform: rotate(144deg) skewY(-54deg);
}
.circle-menu-wrap li:nth-child(6) {
  -webkit-transform: rotate(180deg) skewY(-54deg);
  -ms-transform: rotate(180deg) skewY(-54deg);
  transform: rotate(180deg) skewY(-54deg);
}
.circle-menu-wrap li:nth-child(7) {
  -webkit-transform: rotate(216deg) skewY(-54deg);
  -ms-transform: rotate(216deg) skewY(-54deg);
  transform: rotate(216deg) skewY(-54deg);
}
.circle-menu-wrap li:nth-child(8) {
  -webkit-transform: rotate(252deg) skewY(-54deg);
  -ms-transform: rotate(252deg) skewY(-54deg);
  transform: rotate(252deg) skewY(-54deg);
}
.circle-menu-wrap li:nth-child(9) {
  -webkit-transform: rotate(288deg) skewY(-54deg);
  -ms-transform: rotate(288deg) skewY(-54deg);
  transform: rotate(288deg) skewY(-54deg);
}
.circle-menu-wrap li:nth-child(10) {
  -webkit-transform: rotate(324deg) skewY(-54deg);
  -ms-transform: rotate(324deg) skewY(-54deg);
  transform: rotate(324deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu-small li:first-child {
  -webkit-transform: rotate(0deg) skewY(-54deg);
  -ms-transform: rotate(0deg) skewY(-54deg);
  transform: rotate(0deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu-small li:nth-child(2) {
  -webkit-transform: rotate(36deg) skewY(-54deg);
  -ms-transform: rotate(36deg) skewY(-54deg);
  transform: rotate(36deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu-small li:nth-child(3) {
  -webkit-transform: rotate(72deg) skewY(-54deg);
  -ms-transform: rotate(72deg) skewY(-54deg);
  transform: rotate(72deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu-small li:nth-child(4) {
  -webkit-transform: rotate(108deg) skewY(-54deg);
  -ms-transform: rotate(108deg) skewY(-54deg);
  transform: rotate(108deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu-small li:nth-child(5) {
  -webkit-transform: rotate(143deg) skewY(-54deg);
  -ms-transform: rotate(143deg) skewY(-54deg);
  transform: rotate(143deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu-small li:nth-child(6) {
  -webkit-transform: rotate(180deg) skewY(-54deg);
  -ms-transform: rotate(180deg) skewY(-54deg);
  transform: rotate(180deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu-small li:nth-child(7) {
  -webkit-transform: rotate(216deg) skewY(-54deg);
  -ms-transform: rotate(216deg) skewY(-54deg);
  transform: rotate(216deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu-small li:nth-child(8) {
  -webkit-transform: rotate(252deg) skewY(-54deg);
  -ms-transform: rotate(252deg) skewY(-54deg);
  transform: rotate(252deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu-small li:nth-child(9) {
  -webkit-transform: rotate(288deg) skewY(-54deg);
  -ms-transform: rotate(288deg) skewY(-54deg);
  transform: rotate(288deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu-small li:nth-child(10) {
  -webkit-transform: rotate(325deg) skewY(-54deg);
  -ms-transform: rotate(325deg) skewY(-54deg);
  transform: rotate(325deg) skewY(-54deg);
}
.circle-menu-wrap .circle-menu {
  background-color: #46bbdb;
}
.circle-menu-wrap .circle-menu-small {
  background-color: #2e89d3;
}
.circle-menu-wrap .circle-menu-wrap .text:hover {
  background: gold;
  cursor: pointer;
}
.circle-menu-wrap.circle-menu-opened-nav {
  pointer-events: auto;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
