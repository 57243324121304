.keyboardArrows {
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 102px;
  width: 156px;
  z-index: 9999999;
}
.keyboardArrows button {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  height: 48px;
  width: 48px;
  outline: none;
  cursor: pointer;
}
.keyboardArrows .keyboardRight {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotate(90deg);
}
.keyboardArrows .keyboardLeft {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: rotate(-90deg);
}
.keyboardArrows .keyboardUp {
  position: absolute;
  top: 0;
  left: 54px;
}
.keyboardArrows .keyboardDown {
  position: absolute;
  bottom: 0;
  left: 54px;
  transform: rotate(180deg);
}
.resizableWindow {
  position: fixed !important;
  z-index: 1000;
}
.resizableWindow.activeWindow {
  z-index: 9999;
  opacity: 1;
}
.resizableWindow .windowModal {
  overflow: hidden;
  border-radius: 10px;
}
.resizableWindow .windowModal .windowTitle {
  cursor: move !important;
  position: relative;
  z-index: 1;
}
.resizableWindow .windowModal .windowTitle a {
  cursor: pointer !important;
}
.resizableWindow .windowModal .windowTitle div {
  cursor: move !important;
}
.resizableWindow .windowModal .windowTitle div:first-child {
  display: inline-block;
  width: 100%;
  text-align: left;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  vertical-align: middle !important;
}
.resizableWindow .windowModal .windowView {
  width: 100%;
  display: block !important;
  overflow: hidden;
}
.resizableWindow .windowModal .windowView .windowImage {
  margin: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
.resizableWindow .windowModal .windowView .windowImage.miniImage {
  width: auto;
}
.resizableWindow .windowModal .windowView .windowBackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  margin: auto;
}
.resizableWindow .windowModal .windowView .player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* 720 / 1280 = 0.5625 */
}
.resizableWindow .windowModal .windowView .player-wrapper.video-player {
  top: 50%;
  transform: translateY(-50%);
}
.resizableWindow .windowModal .windowView .tiktok-wrapper {
  position: relative;
  height: 100%;
  top: 20px;
}
.resizableWindow .windowModal .windowView .react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.resizableWindow .windowModal .windowView .react-player video {
  background-color: black;
}
.resizableWindow .windowModal .windowView .audioVisualizerCanvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 32px;
}
.resizableWindow .windowModal .windowView .audioFile {
  width: 100% !important;
  height: 100% !important;
  bottom: 0;
  position: absolute;
  background: url("../images/play-music.png") no-repeat center 20%;
}
.resizableWindow .windowModal .windowView .react-pdf__Page__canvas {
  margin: auto;
}
.resizableWindow .windowModal .windowView .downloadWrap {
  position: absolute;
  top: 50%;
  left: 0;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
  width: 100%;
}
.resizableWindow .windowModal .windowView .downloadWrap button {
  margin-top: 15px;
}
.resizableWindow .windowModal.block {
  display: block !important;
}
.resizableWindow .windowModal.document > div:last-child {
  display: inline !important;
  padding: 0 !important;
}
.resizableWindow .windowModal.document .windowView {
  height: 100%;
  height: calc(100% - 31px);
}
.resizableWindow .windowModal.document .windowView.scroll {
  overflow: scroll;
}
.resizableWindow .windowModal.document .windowView.scrollV {
  overflow-y: scroll;
}
.resizableWindow .windowModal.document .windowView.scrollH {
  overflow-x: scroll;
}
.resizableWindow .windowModal.video-modal > div:last-child {
  width: 100%;
  height: 100% !important;
  position: absolute !important;
  top: 0;
}
.resizableWindow .windowModal.news-modal > div:last-child {
  overflow-y: auto;
}
.resizableWindow .windowModal.news-modal .date {
  font-size: 0.8em;
}
.windowMinimize {
  height: 31px;
  padding: 3px 10px 3px 5px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  font-size: 14px;
  background-color: white;
  border: 1px solid #46bbdb;
  outline: none;
  cursor: pointer;
}
.windowMinimize span {
  display: inline-block;
  width: 100%;
  text-align: left;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  vertical-align: middle !important;
}
.windowMinimize span svg {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.windowMinimize span path {
  fill: #46bbdb;
}
