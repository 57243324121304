.keyboardArrows {
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 102px;
  width: 156px;
  z-index: 9999999;
}
.keyboardArrows button {
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  height: 48px;
  width: 48px;
  outline: none;
  cursor: pointer;
}
.keyboardArrows .keyboardRight {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotate(90deg);
}
.keyboardArrows .keyboardLeft {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: rotate(-90deg);
}
.keyboardArrows .keyboardUp {
  position: absolute;
  top: 0;
  left: 54px;
}
.keyboardArrows .keyboardDown {
  position: absolute;
  bottom: 0;
  left: 54px;
  transform: rotate(180deg);
}
.mobile-modal {
  width: 95% !important;
  height: 95% !important;
  margin: 2.5% !important;
  top: 1% !important;
}
.mobile-modal .mobile-modal-content {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.mobile-modal .mobile-modal-title {
  display: block;
  position: relative;
  z-index: 1;
  width: 85%;
  height: 30px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  vertical-align: middle !important;
}
.mobile-modal .close {
  z-index: 2 !important;
}
.mobile-modal .mobile-modal-image,
.mobile-modal .downloadWrap,
.mobile-modal .mobile-modal-audio {
  max-width: 95%;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-user-drag: none;
}
.mobile-modal .downloadWrap {
  width: 95%;
  text-align: center;
}
.mobile-modal video {
  background-color: black;
}
.mobile-modal .mobile-modal-audio {
  width: 100%;
}
.mobile-modal .audioFile {
  background: url("../images/play-music.png") no-repeat center center;
}
.mobile-modal .mobile-modal-audio > div,
.mobile-modal .audioVisualizerCanvas,
.mobile-modal .audioFile {
  width: 100% !important;
  height: 300px !important;
}
.mobile-modal .audioVisualizerCanvas,
.mobile-modal .audioFile {
  position: absolute;
}
.mobile-modal audio {
  width: 100%;
  height: 100%;
}
.mobile-modal .ratio {
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  top: 50%;
  transform: translate(0%, -50%);
}
.mobile-modal .react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 90% !important;
  align-items: center;
  text-align: center;
}
.mobile-modal .tiktok-wrapper .react-player {
  height: 100% !important;
  margin-top: 45px;
}
