.startupAdWrap {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 240px;
  height: 240px;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-border-top-right-radius: 240px;
  -moz-border-radius-topright: 240px;
  border-top-right-radius: 240px;
  overflow: hidden;
  text-align: center;
  font-size: 16px;
  box-shadow: 3px 3px 10px 3px #c4c4c4;
}
.startupAdWrap .closeAd {
  position: absolute;
  top: 5px;
  left: 0px;
  font-size: 24px;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  margin: 0;
  padding: 0;
  color: #333;
}
.startupAdWrap .startupAd {
  width: 160px;
  position: absolute;
  left: 0;
  top: 30px;
}
.startupAdWrap .startupAd .desc {
  width: 150px;
  margin-left: 8px;
  font-weight: bold;
  margin-top: 10px;
  text-align: left;
}
.startupAdWrap .startupAd a {
  background-color: #333 !important;
}
.startupAdWrap img {
  width: 80%;
  display: block;
  margin: 10px auto 15px auto;
}
