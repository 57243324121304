.sticky-menu {
  width: 100%;
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
}
.sticky-menu .scroll-menu {
  width: 100%;
  height: 70px;
  background-color: white;
  z-index: 10;
  position: fixed;
  overflow-y: hidden;
  top: -110px;
  left: 0;
  transition: all 0.8s ease-out;
  opacity: 0.95;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
}
.sticky-menu .scroll-menu.slide-in {
  top: 0;
}
.sticky-menu .scroll-menu.slide-out {
  top: -110px;
}
.sticky-menu .scroll-menu .App-logo {
  margin: 5px 20px;
  width: auto;
  float: left;
}
.sticky-menu .scroll-menu .App-logo img {
  width: auto;
  height: 60px;
}
.sticky-menu .scroll-menu .cloudMenuWrap {
  width: 100%;
  max-width: none;
  margin: 0;
}
.sticky-menu .scroll-menu .cloudMenuInner {
  margin: 0;
  /*	.ui.icon.menu .item:not(.disabled) {
                  color: @side-bar-background-color;
              } */
}
.sticky-menu .scroll-menu .cloudMenuInner .ui.menu {
  border-top: 0;
  border-bottom: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
.sticky-menu .scroll-menu .cloudMenuInner .ui.menu .item {
  border-radius: 0;
  min-width: 8em;
  padding: 0.92857143em;
}
.sticky-menu .scroll-menu .cloudMenuInner > .left {
  float: right;
  margin-top: 1px;
}
.sticky-menu.not-auth .scroll-menu {
  overflow: hidden !important;
}
.sticky-menu.not-auth .scroll-menu .burger {
  color: #2e89d3;
  top: 13px;
  left: 35px;
}
.sticky-menu.not-auth .scroll-menu .btnLogin {
  color: #2e89d3;
  -webkit-box-shadow: 0 0 0 2px #2e89d3 inset !important;
  box-shadow: 0 0 0 2px #2e89d3 inset !important;
  float: right;
  margin: 15px 35px;
}
.sticky-menu.not-auth .scroll-menu .btnLogin:hover {
  color: #fff;
  background-color: #2e89d3;
  -webkit-box-shadow: 0 0 0 2px #2e89d3 inset !important;
  box-shadow: 0 0 0 2px #2e89d3 inset !important;
}
.sticky-menu.not-auth .scroll-menu .App-logo {
  float: none;
  width: 200px;
  margin: 5px auto;
}
.sticky-menu.not-auth .scroll-menu .App-logo img {
  width: 200px;
  height: auto;
}
