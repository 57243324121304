.faq h1 {
  text-transform: capitalize !important;
  color: #46bbdb !important;
  text-shadow: 1px 1px 1px rgba(37, 128, 219, 0.2);
}
.faq .accordion {
  overflow: hidden;
}
.faq .accordion .title {
  background-color: #46bbdb !important;
  color: #fff !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}
.faq .accordion h3 {
  padding-top: 10px;
}
.faq .accordion strong.underline {
  text-decoration: underline;
}
