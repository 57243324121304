@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype');
}
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: white;
  color: #333;
  font-size: 16px;
}
html,
body,
#root,
#root > div {
  -webkit-overflow-scrolling: touch;
  height: 100%;
}
/*
#root > div {
	min-height: 100%;
    height: 100%;
}
*/
.clearfix:after {
  content: "";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.App-logo {
  margin: 60px auto 0;
  display: block;
  width: 130px;
}
.App-logo img {
  width: 130px;
}
.burger {
  color: #fff;
  position: absolute;
  font-size: 3em !important;
  top: 20px;
  left: 20px;
  cursor: pointer;
}
.news {
  color: #fff;
  position: absolute;
  top: 19px;
  left: 110px;
  cursor: pointer;
  height: auto;
  width: 51px;
}
.news path {
  fill: #fff;
  stroke: #fff;
  stroke-width: 0.4px;
}
.news-mobile {
  overflow-y: auto;
  height: 95%;
}
.weather {
  position: absolute;
  display: block;
  margin: 0px auto;
  width: 80px;
  right: 20px;
  top: 80px;
}
.weather img {
  width: 80px;
  height: 80px;
  opacity: 0.85;
}
.weather .temperature {
  position: absolute;
  color: white;
  top: 17px;
  right: 14px;
  text-shadow: -1px -1px 0 #000, 2px -1px 0 #000, -1px 2px 0 #000, 1px 2px 0 #000;
}
.weather .temperature strong {
  font-size: 28px;
}
.weather .temperature span {
  position: absolute;
  top: -4px;
  font-size: small;
}
.overflowY {
  overflow-y: auto !important;
}
.notification-action-button {
  cursor: pointer;
}
.mb30 {
  margin-bottom: 30px !important;
}
.no-border {
  border: 0;
}
.cloud-content {
  display: block;
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 160px;
  /*	
	li {
		float: left;
		margin: 0;
		width: 70px;
		height: 70px;
		font-size: 0;
		vertical-align: middle;
		box-shadow: 0 0 1px transparent;
		cursor: pointer;

		img {
			width: auto;
			height: 100%
		}
	}
*/
}
.cloud-content .cloudMainContent {
  padding: 0 20px 0 20px;
}
.cloud-content .ui.breadcrumb a {
  color: #46bbdb;
}
.cloud-content .ui.breadcrumb a:hover {
  color: #2e89d3;
}
.cloud-content .breadcrumb,
.cloud-content .resultsBox {
  margin-bottom: 20px !important;
}
.cloud-content.cloud-content-mobile .file-tile-box.active,
.cloud-content.cloud-content-mobile .file-tile-box.active:hover {
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
}
.cloud-content.cloud-content-mobile .file-tile-box:hover {
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
}
.cloud-content.cloud-content-mobile .file-tile-box .file-name {
  overflow: hidden;
}
@-webkit-keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.uppy-DashboardTabs ul li:hover,
.uppy-DashboardTabs ul li:focus,
.uppy-DashboardTabs ul li:active,
.burger:hover,
.burger:focus,
.burger:active,
.news:hover,
.news:focus,
.news:active {
  -webkit-animation-name: hvr-pop;
  animation-name: hvr-pop;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.closeSearch {
  position: absolute;
  right: 20px;
  margin-top: 20px;
}
.gsc-control-cse {
  border: 0 !important;
}
.dimmer {
  position: fixed !important;
  z-index: 99999999 !important;
}
.pushableWrap {
  border: 0 !important;
  border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  margin: 0 !important;
}
.pushableWrap .pusher {
  box-shadow: -7px 0 9px -6px rgba(0, 0, 0, 0.4);
}
.pushableWrap .pusher .segmentMain {
  margin: 0;
  padding: 0;
  height: 100%;
  position: initial !important;
}
.pushableWrap .pusher .segmentMain .notifications-tr {
  top: 70px !important;
}
.leftSideBar.ui.wide.left.sidebar {
  position: fixed;
  overflow-y: hidden !important;
}
.leftSideBar.ui.wide.left.sidebar,
.leftSideBar.ui.wide.right.sidebar {
  width: 200px;
  background: #2e89d3;
}
.leftSideBar.ui.visible.wide.left.sidebar ~ .fixed,
.leftSideBar.ui.visible.wide.left.sidebar ~ .pusher {
  -webkit-transform: translate3d(200px, 0, 0);
  transform: translate3d(200px, 0, 0);
}
.leftSideBar.ui.labeled.icon.menu .item {
  text-align: left;
}
.leftSideBar.ui.labeled.icon.menu .item span {
  position: relative;
  top: -4px;
}
.leftSideBar.ui.labeled.icon.menu .item .icon:not(.dropdown) {
  margin: 0 10px 0 0 !important;
  display: inline-block;
  height: 1em;
  font-size: 1.71428571em !important;
}
.leftSideBar .share-content {
  padding: 0 !important;
}
.leftSideBar .share-content .share-content-inner {
  padding: 10px 25px;
}
.leftSideBar .share-content .share-button {
  float: left;
  margin: 10px;
  cursor: pointer;
}
.leftSideBar .link.item {
  padding: 0 !important;
  margin: 0;
}
.leftSideBar .link.item a {
  padding: 15px 20px;
  display: block;
}
.leftSideBar .pointer.item {
  width: 100%;
}
.leftSideBar .pointer.item:hover {
  background: rgba(255, 255, 255, 0.08);
}
.ui.icon.menu .item.disabled {
  color: rgba(40, 40, 40, 0.3);
}
.loading {
  stroke: #46bbdb;
  fill: #46bbdb;
  height: 90px;
  width: 90px;
  margin: auto;
}
.file-tile-box,
.file-w,
.file-name,
.file-w img,
.file-tile-box:active,
.file-w:active,
.file-name:active,
.file-w img:active,
.file-tile-box:focus,
.file-w:focus,
.file-name:focus,
.file-w img:focus,
.iconName,
.iconNameWrap,
.iconImage,
.size,
.date {
  outline: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.myBreadcrumb {
  margin: 0 10px !important;
  float: left;
}
.myBreadcrumb .spanDivider:first-child .chevron.divider {
  display: none;
}
.sortBy {
  float: right;
  font-size: 14px;
}
.sortBy .menu {
  left: -55px !important;
  bottom: -130px !important;
}
.profile {
  position: absolute !important;
  top: 20px;
  right: 20px;
}
.profile a {
  color: #333;
}
.profile .avatar {
  border: 2px solid white;
  width: 48px !important;
}
.profile .profile-name {
  line-height: 50px;
  color: white;
  text-transform: capitalize;
}
.uppy-DashboardItem-edit {
  display: none;
}
.uppy-Dashboard-inner * {
  outline: none;
}
.uppy-Dashboard-overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
.ui.table thead th {
  padding: 0 !important;
}
.ui.table td.active,
.ui.table tr.active {
  color: white !important;
  background: #2e89d3 !important;
}
.noTransform {
  transform: none !important;
}
.windowPadding {
  padding: 12px;
}
ul.pager {
  text-align: center;
  position: fixed;
  bottom: 10px;
  width: 100%;
}
ul.pager li {
  display: inline-block;
}
ul.pager .pageInfo {
  margin: 0 10px 0 7px;
  border-radius: 5px 5px 5px 5px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 7px 10px 5px;
  border: 3px solid lightgrey;
}
ul.pager .zoomPdf {
  position: relative;
  top: -4px;
  padding: 9px 8px 12px 8px !important;
}
@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  1% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.fadein {
  animation: FadeIn 0.5s linear;
  animation-fill-mode: both;
}
@keyframes FadeInRow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeinRow {
  animation: FadeInRow 1s linear;
  animation-fill-mode: both;
}
.pushable:not(body) {
  -webkit-transform: none;
  transform: none;
}
.border-top {
  height: 1px;
  width: 100%;
  position: fixed;
  top: -3px;
  border: 0;
  -webkit-box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.4);
  left: 0;
  right: 0;
  z-index: 3;
}
.footer {
  position: absolute;
  bottom: 5px;
  color: #999;
  font-size: 12px;
}
.footer a {
  color: #8dc251;
}
.browserNotificationForm {
  margin-top: 2em !important;
  margin-bottom: 3em !important;
}
.browserNotificationForm .text-field {
  display: block !important;
  margin-bottom: 10px;
  z-index: 9;
}
.browserNotificationForm .text-field input[type="text"],
.browserNotificationForm .text-field textarea {
  border: 0;
  background: transparent;
  padding: 6px 0 7px 0;
}
.googleAds {
  width: 100%;
  display: table;
  font-size: 34px;
  color: white;
  font-weight: 500;
}
.googleAds ins.adsbygoogle > ins {
  margin: 0 auto !important;
  display: block !important;
}
.noBackground {
  background-color: rgba(0, 0, 0, 0) !important;
}
.noBackground .loader {
  color: #2e89d3 !important;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
  font-size: 44px !important;
}
.noBackground .loader:before {
  border-color: rgba(46, 137, 211, 0.1) !important;
}
.noBackground .loader:after {
  border-color: #2e89d3 transparent transparent !important;
}
.side-menu-active .circle-menu-button,
.side-menu-active .circle-menu-wrap {
  display: none;
}
.open-element {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: white !important;
  background-color: #43b0d4 !important;
  z-index: 21;
  border: 4px solid white !important;
  border-radius: 50% !important;
  width: 64px !important;
  height: 64px !important;
  padding: 0 !important;
}
.introText {
  text-align: center;
  box-shadow: none !important;
  border: 0 !important;
}
.introText .segment {
  border: 0 !important;
}
.introText h2 i {
  font-size: 4em !important;
  color: #2e89d3;
}
.introText .desktopH2 {
  font-size: 0.9em !important;
  display: block;
  line-height: 1.5em;
}
.threeIcons {
  max-width: 1400px;
  margin: auto;
}
.introVideoWrap {
  width: 60%;
  max-width: 900px;
  margin: 10px auto 70px auto;
}
.introVideoWrap .introTitle {
  margin-top: 50px !important;
  color: #46bbdb;
}
.introVideoWrap .introTitle img {
  width: 1.8em !important;
  margin-top: 0.5em !important;
}
.introVideoWrap .introVideo {
  border: 10px solid #e6f6f9;
  -webkit-box-shadow: 1px 2px 6px 2px rgba(51, 51, 60, 0.3);
  -moz-box-shadow: 1px 2px 6px 2px rgba(51, 51, 60, 0.3);
  box-shadow: 1px 2px 6px 2px rgba(51, 51, 60, 0.3);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.introVideoWrap .introVideo .react-player {
  line-height: 0;
}
.amazonModal .content {
  text-align: center;
}
.amazonProducts {
  display: inline;
}
.amazonProducts .amazonItem {
  width: 120px;
  height: 240px;
}
.introTextComponentWrap,
.introTopTextComponentWrap {
  width: 100%;
  background-color: #46bbdb;
  margin: 30px 0 30px 0;
  color: white;
  font-size: 16px;
  text-align: left;
}
.introTextComponentWrap .introTextComponent,
.introTopTextComponentWrap .introTextComponent {
  display: block;
  max-width: 1500px;
  margin: 20px auto;
}
.introTextComponentWrap .introTextComponent img,
.introTopTextComponentWrap .introTextComponent img {
  margin: 0 auto 20px auto;
}
.introTextComponentWrap .introTextComponent .cloudos-in-action-video,
.introTopTextComponentWrap .introTextComponent .cloudos-in-action-video {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
}
.introTextComponentWrap .introTextComponent .cloudos-in-action-video video,
.introTopTextComponentWrap .introTextComponent .cloudos-in-action-video video {
  margin-top: -1px;
}
.introTextComponentWrap .bottomClouds,
.introTopTextComponentWrap .bottomClouds,
.introTextComponentWrap .topClouds,
.introTopTextComponentWrap .topClouds {
  background: url("../images/bg_clouds_mobile2.png") repeat-x top center;
  width: 100%;
  height: 149px;
}
.introTextComponentWrap .topClouds,
.introTopTextComponentWrap .topClouds {
  transform: scaleY(-1);
}
.introTextComponentWrap h3,
.introTopTextComponentWrap h3 {
  color: white !important;
}
.introTextComponentWrap strong,
.introTopTextComponentWrap strong {
  font-weight: 500;
  text-decoration: underline;
}
.introTopTextComponentWrap {
  background-color: transparent;
  color: #333;
}
.introTopTextComponentWrap h2,
.introTopTextComponentWrap h3 {
  color: #46bbdb !important;
}
.introTopTextComponentWrap img {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  margin: 0 auto !important;
}
.introTopTextComponentWrap .row {
  margin-bottom: 40px;
}
.react-cookie-banner {
  display: block;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999999;
  font-size: 14px;
}
.react-cookie-banner a {
  color: white;
  text-decoration: underline;
  margin-left: 10px;
}
.react-cookie-banner button {
  float: right;
  margin: 0 10px;
  padding: 3px 15px;
  border-radius: 3px;
  outline: none;
  background-color: white !important;
  cursor: pointer;
}
.lblgreen {
  color: #1ebc30 !important;
}
.FontAwesome {
  margin: 0 10px 0 0 !important;
  display: inline-block;
}
.pointer {
  cursor: pointer !important;
}
.loginFixed {
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: block;
  text-align: center;
}
.loginFixed .label,
.loginFixed button,
.loginFixed i {
  color: white;
  background-color: #2e89d3 !important;
  cursor: pointer;
}
.loginFixed .label {
  display: block;
}
#emptybox {
  display: none;
}
.likeCloudOS .ui.checkbox {
  display: block;
  margin-top: 10px;
  font-size: 14px;
}
.likeWebsiteShareWrap .share-button {
  width: 33%;
  float: left;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
}
.likeWebsiteShareWrap .share-button > div {
  margin: 10px auto;
}
.likeWebsiteShareWrap.faq a.primary.button {
  width: 122px;
  margin: auto;
  display: block;
}
.modal .Cloud-Input {
  min-width: 80%;
  margin-bottom: 10px;
}
.modal .Cloud-Group {
  width: 180px;
  margin-bottom: 10px;
}
.modal .Cloud-Group > label {
  height: 30px;
}
.modal .File-Extension {
  display: inline-block;
  margin-top: 20px;
}
/*        Dark Mode Start         */
/* ------------------------------- */
.darkMode {
  background-color: #1e1f22 !important;
  color: white;
}
.darkMode .leftSideBar.ui.wide.left.sidebar,
.darkMode .cloudMainContent .ui.menu {
  background-color: #2b2c2f !important;
}
.darkMode .pushableWrap,
.darkMode .cloud-content,
.darkMode .cloudMenuWrap,
.darkMode .threeIcons .introText,
.darkMode .scroll-menu .cloudMenuInner .ui.mini.menu {
  background-color: #1e1f22 !important;
  color: white;
}
.darkMode .scroll-menu .cloudMenuInner .ui.mini.menu .item::before {
  background: rgba(255, 255, 255, 0.1);
}
.darkMode .cloudHead {
  background: #5a6d84;
  background: -moz-linear-gradient(top, #0f1f37 0%, #5a6d84 40%);
  background: -webkit-linear-gradient(top, #0f1f37 0%, #5a6d84 40%);
  background: linear-gradient(to bottom, #0f1f37 0%, #5a6d84 40%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#0f1f37, endColorstr=#5a6d84, GradientType=0);
}
.darkMode .cloudHead .parallax-clouds.cloud1 {
  background: url("../images/bg_cloud-dark2-layer1.png") repeat-x bottom;
}
.darkMode .cloudHead .parallax-clouds.cloud2 {
  background: url("../images/bg_cloud-dark-layer2.png") repeat-x bottom;
}
.darkMode .cloudHead .parallax-clouds.cloud3 {
  background: url("../images/bg_cloud-dark-layer3.png") repeat-x bottom;
}
.darkMode .cloudHead .parallax-clouds.mobile {
  background: url("../images/bg_dark_clouds_mobile2.png") #5a6d84 repeat-x bottom;
}
.darkMode .cloudHead .btnLogin:hover {
  color: #1e1f22 !important;
}
.darkMode .ui.icon.menu .item.disabled {
  color: rgba(0, 0, 0, 0.5) !important;
}
.darkMode .threeIcons h2,
.darkMode .updates-feeds,
.darkMode .ui.breadcrumb .divider,
.darkMode h1.header,
.darkMode .ui.menu .item:not(.disabled) {
  color: white !important;
}
.darkMode .ui.menu .item:not(.disabled):hover {
  background-color: #545659 !important;
}
.darkMode .file-tile-box {
  background-color: #545659;
  color: white;
}
.darkMode .file-tile-box .hoverName {
  color: white !important;
}
.darkMode .file-tile-box:hover {
  color: #46bbdb !important;
}
.darkMode .file-tile-box.active:hover {
  color: white !important;
}
.darkMode .listLayoutTable tr:hover td {
  color: #46bbdb;
}
.darkMode .listLayoutTable tr.active td,
.darkMode .listLayoutTable tr.active:hover td {
  background-color: #2e89d3;
  color: white;
}
.darkMode .ui.dropdown .menu {
  background-color: #2b2c2f;
  border-width: 2px;
  color: white;
}
.darkMode .ui.dropdown .menu:after {
  background-color: #2b2c2f;
}
.darkMode .ui.dropdown .menu .item,
.darkMode .ui.dropdown .menu a,
.darkMode .ui.dropdown .menu span,
.darkMode .ui.dropdown .menu i,
.darkMode .ui.dropdown .menu .header {
  color: white;
}
.darkMode .ui.dropdown .menu .item:hover {
  background-color: #545659 !important;
}
.darkMode .ui.menu .ui.dropdown .menu > .item:hover {
  background-color: #545659 !important;
}
.darkMode .modal {
  background: #1e1f22;
}
.darkMode .modal .content {
  background: #1e1f22;
  color: white;
}
.darkMode .modal .content label {
  color: white !important;
}
.darkMode .modal .content input[type="email"],
.darkMode .modal .content input[type="password"],
.darkMode .modal .content input[type="search"],
.darkMode .modal .content input[type="text"] {
  color: white !important;
}
.darkMode .modal .header,
.darkMode .modal .actions {
  color: white;
  background: #2b2c2f;
}
.darkMode .modal .description > .header {
  background: #1e1f22;
}
.darkMode .modal .description svg,
.darkMode .modal .description svg path {
  fill: white;
  stroke: white;
  color: white;
}
.darkMode .mobile-modal .close.icon {
  color: white;
}
.darkMode .account {
  color: white;
}
.darkMode .account h1 img {
  border-color: white !important;
}
.darkMode .account label,
.darkMode .account .ui.toggle.checkbox input:checked ~ label,
.darkMode .account .ui.statistic > .label {
  color: white !important;
}
.darkMode .account .ui.toggle.checkbox .box:before,
.darkMode .account .ui.toggle.checkbox label:before,
.darkMode .account .SimpleProgressBarWrap {
  background: rgba(0, 0, 0, 0.5);
}
.darkMode .popup {
  background-color: #2b2c2f;
  color: white;
}
.darkMode .ui.top.popup:before {
  background: #2b2c2f;
}
.darkMode .ui.table {
  border-color: #545659;
  color: white;
}
.darkMode .ui.table th {
  background: #2b2c2f;
}
.darkMode .ui.table th button {
  color: white !important;
}
.darkMode .ui.table tr:nth-of-type(odd) {
  background: #545659 !important;
}
.darkMode .ui.table tr:nth-of-type(even) {
  background: #2b2c2f !important;
}
.darkMode .uppy-Dashboard-inner {
  background: #1e1f22;
}
.darkMode .uppy-Dashboard-inner .uppy-DashboardTab-btn:hover {
  color: #1e1f22;
}
.darkMode .uppy-Dashboard-inner .uppy-StatusBar-statusPrimary,
.darkMode .uppy-Dashboard-inner .uppy-DashboardContent-title,
.darkMode .uppy-Dashboard-inner .uppy-StatusBar-actions,
.darkMode .uppy-Dashboard-inner .uppy-Dashboard-Item-name,
.darkMode .uppy-Dashboard-inner .uppy-Dashboard-AddFiles-title,
.darkMode .uppy-Dashboard-inner .uppy-DashboardTab-btn,
.darkMode .uppy-Dashboard-inner .uppy-Dashboard-dropFilesTitle,
.darkMode .uppy-Dashboard-inner .uppy-Provider-breadcrumbs {
  color: white;
}
.darkMode .uppy-Dashboard-inner .UppyIcon {
  fill: white;
  stroke: white;
}
.darkMode .uppy-Dashboard-inner .uppy-DashboardItem-remove .UppyIcon {
  stroke: #1e1f22;
}
.darkMode .uppy-Dashboard-inner .uppy-StatusBar,
.darkMode .uppy-Dashboard-inner .uppy-ProviderBrowser-headerBar,
.darkMode .uppy-Dashboard-inner .uppy-ProviderBrowser-search {
  background-color: #2b2c2f;
}
.darkMode .uppy-Dashboard-inner .uppy-DashboardContent-panel,
.darkMode .uppy-Dashboard-inner .uppy-DashboardContent-bar,
.darkMode .uppy-Dashboard-inner .uppy-ProviderBrowser-list {
  background: #1e1f22;
}
.darkMode .uppy-Dashboard-inner .uppy-ProviderBrowser-search input {
  color: white;
}
.darkMode .windowModal {
  background-color: #1e1f22 !important;
}
.darkMode .windowModal .windowTitle {
  background: #2b2c2f !important;
  color: white !important;
  overflow: hidden;
}
.darkMode .windowModal .windowTitle a rect,
.darkMode .windowModal .windowTitle a path,
.darkMode .windowModal .windowTitle a polygon {
  fill: white !important;
}
.darkMode .windowModal .windowTitle a:hover,
.darkMode .windowModal .windowTitle a:hover,
.darkMode .windowModal .windowTitle a:hover {
  background-color: #545659 !important;
}
.darkMode .windowTitle div:first-child {
  color: white !important;
}
.darkMode .windowMinimize {
  color: white;
  background-color: #2b2c2f;
  border-color: white;
}
.darkMode .windowMinimize svg path {
  fill: white;
}
.darkMode .resultsBox .gsc-control-cse {
  background-color: #1e1f22;
}
.darkMode .resultsBox .gsc-above-wrapper-area {
  border-color: white;
}
.darkMode .resultsBox .gs-result .gs-title,
.darkMode .resultsBox .gs-result .gs-title *,
.darkMode .resultsBox .gsc-result-info,
.darkMode .resultsBox .gs-webResult .gs-snippet {
  color: white !important;
}
.darkMode .resultsBox .gsc-webResult.gsc-result {
  background-color: #1e1f22;
  border-color: #1e1f22;
}
.darkMode .resultsBox .gsc-results .gsc-cursor-page {
  background-color: #1e1f22 !important;
  color: white !important;
}
.darkMode .resultsBox .gsc-results .gsc-cursor-current-page {
  text-decoration: underline;
  font-weight: bold;
}
.darkMode .paypalBanner {
  padding: 0 10px;
  background-color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.darkMode .ui.card,
.darkMode .ui.cards > .card {
  color: white;
  background-color: #545659;
}
.darkMode .ui.card .description,
.darkMode .ui.cards > .card .description,
.darkMode .ui.card .header,
.darkMode .ui.cards > .card .header {
  color: white;
}
.darkMode .startup.button {
  background-color: #2b2c2f;
}
.darkMode .startup.button:hover {
  background-color: #545659;
}
.darkMode .faq .accordion {
  background-color: #545659 !important;
}
.darkMode .faq .accordion .title {
  background: #2b2c2f !important;
}
.darkMode .updates-feeds .noUpdates {
  color: #545659 !important;
}
.darkMode .contact .message {
  background: #2b2c2f;
}
.darkMode .contact .form {
  background-color: #545659;
  border: 0;
}
.darkMode .contact .form label {
  color: white !important;
}
.darkMode .contact .form input[type="email"],
.darkMode .contact .form input[type="password"],
.darkMode .contact .form input[type="search"],
.darkMode .contact .form input[type="text"] {
  color: white !important;
}
.darkMode .terms h3 {
  color: white;
}
.darkMode .introTextComponentWrap {
  background-color: #5a6d84;
}
.darkMode .introTextComponentWrap .bottomClouds,
.darkMode .introTextComponentWrap .topClouds {
  background: url("../images/bg_dark_clouds_mobile2.png") repeat-x top center;
}
.darkMode .introTopTextComponentWrap,
.darkMode .introTopTextComponentWrap h3 {
  color: white !important;
}
.darkMode .page404 {
  background-color: #1e1f22 !important;
}
.darkMode .cloudMenuWrap.mobile .segment {
  background-color: #2b2c2f !important;
}
.darkMode .keyboardArrows button {
  background: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.darkMode .tabsWrap .ui.secondary.pointing.menu {
  border-bottom: 2px solid rgba(255, 255, 255, 0.15);
}
.darkMode .tabsWrap .tab {
  background-color: #1e1f22;
}
.darkMode .tabsWrap .ui.secondary.pointing.menu .item:hover {
  border-bottom: 2px solid white !important;
  background-color: transparent !important;
}
.darkMode .tabsWrap .ui.secondary.pointing.menu .active.item {
  border-color: white !important;
  background-color: #545659 !important;
  color: #fff !important;
}
.darkMode .controlsTitle {
  color: white;
}
.darkMode #not-auth-menu {
  background-color: #2b2c2f !important;
}
.darkMode #not-auth-menu .btnLogin {
  color: white;
  -webkit-box-shadow: 0 0 0 2px white inset !important;
  box-shadow: 0 0 0 2px white inset !important;
}
.darkMode #not-auth-menu .btnLogin:hover {
  color: #1e1f22 !important;
  background-color: white;
}
.darkMode #not-auth-menu .burger {
  color: white !important;
}
.darkMode .likeWebsiteShareWrap svg {
  stroke: none !important;
}
.darkMode .likeWebsiteShareWrap.faq .content.active {
  border: 1px solid #2b2c2f !important;
}
.darkMode .likeWebsiteShareWrap.faq h3 {
  background-color: transparent !important;
}
.darkMode .likeWebsiteShareWrap.faq li:before {
  color: white !important;
}
/*        Dark Mode End         */
/* ------------------------------- */
/*        Responsive Start         */
/* ------------------------------- */
@media (min-width: 1300px) {
  .scroll-menu .cloudMenuWrap .left {
    width: calc(100% - 400px) !important;
  }
  .scroll-menu .cloudMenuWrap .left .item {
    width: 11.1%;
  }
}
@media (min-width: 992px) and (max-width: 1240px) {
  .pricing .pricingColumn {
    max-width: 750px;
  }
}
@media (min-width: 992px) and (max-width: 1165px) {
  .scroll-menu .cloudMenuWrap .left .ui.menu {
    border: 0;
  }
  .scroll-menu .cloudMenuWrap .left .item {
    min-width: auto !important;
    padding: 0.92857143em 1.5em;
  }
  .scroll-menu .cloudMenuWrap .left .item:before {
    width: 0;
  }
}
@media (min-width: 992px) and (max-width: 1105px) {
  .googleSearch form.gsc-search-box {
    width: 475px !important;
  }
  .cloudMenuWrap.full {
    height: auto;
  }
  .cloudMenuWrap.full .cloudMenuInner {
    width: 100%;
    display: block;
  }
  .cloudMenuWrap.full .cloudMenuInner .left {
    float: none;
    width: 718px;
    margin: auto;
  }
  .cloudMenuWrap.full .cloudMenuInner .right {
    float: none;
    margin: 10px auto;
    width: 333px;
  }
}
@media (max-width: 991px) {
  .App-logo,
  .App-logo img {
    width: 100px;
  }
  .cloudHead {
    height: 520px;
  }
  .cloudHead .btnLogin {
    right: 10px;
    padding: 0.78571429em 1em 0.78571429em;
  }
  .burger {
    left: 10px;
  }
  .news {
    left: 80px;
  }
  .googleSearch form.gsc-search-box {
    width: 95% !important;
  }
  .cloudMenuWrap {
    height: auto;
  }
  .cloudMenuInner {
    width: 100%;
    display: block;
  }
  .cloudMenuInner .right {
    margin: 10px auto;
    width: auto;
    float: none !important;
    display: table;
    text-align: center;
  }
  .cloudMenuInner .right .item:nth-child(2) .input.icon {
    width: auto;
  }
  .ui.fullscreen.modal {
    height: 95% !important;
  }
  .searchFolder {
    width: auto !important;
  }
  .leftSideBar.ui.wide.left.sidebar {
    overflow-y: auto !important;
    overflow-x: hidden;
  }
  .introVideoWrap {
    width: 80%;
  }
  .pricing .pricingColumn {
    max-width: 500px;
  }
  .tabsWrap {
    height: auto !important;
  }
  .responsiveImage {
    width: 100% !important;
    height: auto !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .googleSearch form.gsc-search-box {
    width: 475px !important;
  }
}
@media (max-width: 767px) {
  .cloudHead .profile .profile-name {
    display: none;
  }
  .listLayoutTable {
    display: block;
  }
  .listLayoutTable thead {
    display: none !important;
  }
  .listLayoutTable .row {
    height: 120px !important;
  }
  .listLayoutTable .iconCell {
    width: 15% !important;
    min-width: 15% !important;
    float: left !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    position: relative;
  }
  .listLayoutTable .iconName,
  .listLayoutTable .date,
  .listLayoutTable .size {
    width: 75% !important;
    max-width: 75%;
  }
  .listLayoutTable .iconName {
    position: relative;
    left: -10px;
  }
  .listLayoutTable .iconImage {
    overflow: visible !important;
    position: absolute;
    top: 30%;
    transform: translate(0%, -30%);
    max-height: none !important;
  }
  .account h1.header {
    text-align: center !important;
    line-height: 4rem !important;
  }
  .account h1.header img {
    display: block !important;
    text-align: center !important;
    margin: auto !important;
  }
  .account .dataUsage {
    width: auto;
  }
  .account .news-settings ul {
    grid-template-columns: 175px 175px;
  }
  .container.cloud-content {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .introVideoWrap {
    width: 100%;
  }
  .introVideoWrap .introVideo {
    margin: 0 1em;
  }
  .pricing .plan {
    display: block !important;
    flex-direction: inherit !important;
    margin: 1em auto 4em auto !important;
  }
  .introTopTextComponentWrap img {
    margin-bottom: 20px !important;
  }
  .tabsWrap .ui.secondary.menu .item {
    padding: 0.85714286em;
  }
  #not-auth-menu .App-logo {
    display: none;
  }
  #not-auth-menu .App-logo-small {
    display: block;
  }
  #not-auth-menu .App-logo-small img {
    width: 80px;
    margin: auto;
    display: block;
  }
  #not-auth-menu .btnLogin {
    margin: 15px 15px 0 0 !important;
    padding: 0.78571429em 1em 0.78571429em;
  }
  #not-auth-menu .burger {
    left: 25px;
  }
}
@media (max-width: 900px) and (max-height: 400px) {
  .ratio {
    position: relative !important;
    height: 95% !important;
    padding-bottom: 0 !important;
    top: auto !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    transform: none !important;
  }
}
@media (max-width: 550px) {
  .listLayoutTable .iconCell {
    width: 25% !important;
    min-width: 25% !important;
    float: left !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    position: relative;
  }
  .introTitle i {
    display: none !important;
  }
}
@media (max-width: 451px) {
  .mainLayout .file-tile-box {
    width: 40%;
    margin: 5%;
    box-sizing: border-box;
  }
}
.minWidth768,
.minWidth821,
.minWidth992,
.maxWidth767,
.maxWidth820,
.maxWidth991,
.maxWidth10000 {
  display: none !important;
}
@media (min-width: 768px) {
  .minWidth768 {
    display: block !important;
  }
}
@media (min-width: 821px) {
  .minWidth821 {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .minWidth992 {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .maxWidth767 {
    display: block !important;
  }
}
@media (max-width: 820px) {
  .maxWidth820 {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .maxWidth991 {
    display: block !important;
  }
}
@media (max-width: 10000px) {
  .maxWidth10000 {
    display: block !important;
  }
}
/*        Responsive End         */
/* ------------------------------- */
