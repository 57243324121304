.introWrap {
  width: 100%;
  position: relative;
}
.introWrap .Container {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
}
.introWrap .Children {
  width: 100%;
  position: relative;
  height: 210px;
  text-align: center;
}
.introWrap .Dot {
  font-size: 1.5em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 33px;
  float: left;
  margin: 20px 0;
  outline: none;
}
.introWrap .Dots {
  width: 150px;
  text-align: center;
  margin: auto;
  outline: none;
}
.introWrap .carouselIcon {
  width: 120px !important;
  height: 120px !important;
  display: block !important;
  margin: 0 auto 10px !important;
}
.introWrap h2 {
  font-size: 1.1em;
  margin: 20px;
}
.introWrap .tabsWrap {
  max-width: 1000px;
  height: 735px;
  margin: 0 auto 50px auto;
}
.introWrap .tabsWrap .beforeAfterSlider {
  margin: auto;
  overflow: hidden;
}
.introWrap .tabsWrap .beforeAfterSlider .styles_handle__33IZp {
  width: 44px;
  margin-left: -22px;
  background: url("../images/before-after.png") no-repeat center center transparent;
}
.introWrap .tabsWrap .tab {
  border: 0 !important;
}
.introWrap .tabsWrap .responsiveImage {
  width: 100%;
}
.introWrap .tabsWrap .ui.secondary.pointing.menu .item:hover {
  border-color: #2e89d3;
}
.introWrap .tabsWrap .ui.secondary.pointing.menu .active.item {
  border-color: #2e89d3;
  background-color: #2e89d3;
  color: #fff;
}
.introWrap .controlsTitle {
  display: block;
  margin-bottom: 20px !important;
}
.introWrap .slick-slider .slick-slide {
  margin-top: 40px;
}
.introWrap .slick-slider .slick-slide h2 {
  text-align: center;
}
.introWrap .slick-slider .slick-dots li button:before {
  font-size: 14px !important;
}
