.about .ui.header,
.terms .ui.header,
.updates .ui.header {
  margin-bottom: 1rem;
}
.about h1,
.terms h1,
.updates h1 {
  text-transform: capitalize !important;
  color: #46bbdb !important;
  text-shadow: 1px 1px 1px rgba(37, 128, 219, 0.2);
}
.about h2,
.terms h2,
.updates h2 {
  text-transform: capitalize !important;
  color: #2e89d3 !important;
  text-shadow: 1px 1px 1px rgba(37, 128, 219, 0.2);
  text-decoration: underline;
}
.about .img,
.terms .img,
.updates .img {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px, rgba(0, 0, 0, 0.23) 0px 1px 3px;
  width: auto !important;
  float: left;
  shape-outside: ellipse();
  margin: 0 20px 10px 0;
}
.about .startup,
.terms .startup,
.updates .startup {
  display: block !important;
  margin: 20px auto;
}
.about img.startup,
.terms img.startup,
.updates img.startup {
  max-width: 80%;
}
.about button.startup,
.terms button.startup,
.updates button.startup,
.about a.startup,
.terms a.startup,
.updates a.startup {
  margin: 20px auto 60px auto;
  width: 148px;
}
.terms {
  padding: 0 15px;
}
