.account {
  margin-top: 20px;
  padding: 10px;
  box-sizing: border-box;
}
.account h1 {
  text-transform: capitalize !important;
  color: #46bbdb !important;
  text-shadow: 1px 1px 1px rgba(37, 128, 219, 0.2);
}
.account h1 img {
  border: 3px solid #46bbdb !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 3px, rgba(0, 0, 0, 0.23) 0px 1px 3px;
}
.account li {
  list-style: none;
  margin: 10px 0;
  vertical-align: middle;
}
.account strong {
  display: block;
  text-align: center;
  margin: 5px auto 20px auto;
}
.account .status {
  font-weight: 400 !important;
  background-color: #46bbdb !important;
  color: white !important;
}
.account .ui.progress {
  margin: 0 !important;
}
.account .ui.progress .bar > .progress {
  color: white !important;
}
.account .toggle.checkbox label {
  display: inline !important;
  vertical-align: middle;
}
.account .statistics {
  display: inline-block;
  margin: auto;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.account .dataUsage {
  width: 450px;
  margin: 20px auto 150px auto;
  position: relative;
}
.account .dataUsage .circle {
  background-color: #e5e5e5;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 36px;
  color: white;
  line-height: 165px;
}
.account .SimpleProgressBarWrap {
  position: relative;
  display: block;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.28571429rem;
  height: 28px;
  font-size: 1rem;
}
.account .SimpleProgressBarWrap .innerBar {
  background-color: #2185d0;
  color: white;
  font-weight: bold;
  display: block;
  line-height: 1;
  position: relative;
  border-radius: 0.28571429rem;
  height: 28px;
  min-width: 5%;
}
.account .SimpleProgressBarWrap .innerBar .progressNumber {
  white-space: nowrap;
  position: absolute;
  width: auto;
  font-size: 0.92857143em;
  top: 50%;
  right: 0.5em;
  left: auto;
  bottom: auto;
  color: white;
  text-shadow: none;
  margin-top: -0.5em;
  font-weight: 700;
  text-align: left;
}
.account .controlPanel h1 {
  text-align: center;
}
.account .controlPanel .cards {
  margin: 20px auto 50px auto;
}
.account .controlPanel .cards h3 {
  font-size: 1em !important;
}
.account .controlPanel .cards .description span {
  color: #db2828 !important;
  font-weight: bold;
}
.account .controlPanel .cards .message {
  margin: 20px auto;
}
.account .news-settings {
  margin-top: 50px;
}
.account .news-settings .header {
  display: inline-block;
  text-transform: capitalize !important;
  color: #46bbdb !important;
  text-shadow: 1px 1px 1px rgba(37, 128, 219, 0.2);
}
.account .news-settings .pointer {
  margin-top: -10px;
}
.account .news-settings ul {
  display: grid;
  grid-template-columns: 200px 200px 200px;
}
