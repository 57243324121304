.updates h2.header {
  margin-bottom: 0.3em !important;
}
.updates .updateDate {
  display: block;
  font-size: 0.8rem;
  text-decoration: none;
  margin-bottom: 0.3em;
  color: #999;
}
.updates .noUpdates {
  font-size: 3em !important;
  color: rgba(40, 40, 40, 0.2) !important;
  text-align: center;
  text-decoration: none;
  display: block;
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
  text-shadow: none;
}
.updates .updates-feeds {
  margin-bottom: 10rem;
}
.updates .feed {
  margin: 4rem 0;
  padding: 15px;
  box-shadow: 1px 1px 5px rgba(40, 40, 40, 0.2);
}
.updates input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px white !important;
  box-shadow: inset 0 0 0px 9999px white !important;
}
.updates input:focus,
.updates input:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0px 9999px white, 0 0 8px rgba(255, 255, 255, 0) !important;
  box-shadow: inset 0 0 0px 9999px white, 0 0 8px rgba(255, 255, 255, 0) !important;
}
