.page404 {
  margin: 0 auto 10em auto !important;
  border: 0 !important;
  box-shadow: none !important;
}
.page404 img {
  margin: auto;
}
.page404 .goHome {
  display: block !important;
}
.page404 .sadFace {
  margin-top: 3em;
  margin-bottom: 3em;
}
