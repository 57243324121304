.file-tile-box {
  width: 95px;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem;
  background-color: #f2f2f2;
  -webkit-box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  text-decoration: none;
  color: #333;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  margin: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;
}
.file-tile-box.active,
.file-tile-box.active:hover {
  -webkit-transform: translateY(-5px) scale(1);
  transform: translateY(-5px) scale(1);
  -webkit-box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
  box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
  color: white !important;
  background-color: #2e89d3 !important;
  z-index: 1;
}
.file-tile-box:hover {
  -webkit-transform: translateY(-5px) scale(1);
  transform: translateY(-5px) scale(1);
  -webkit-box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
  box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
  color: #2e89d3 !important;
  z-index: 1;
}
.file-tile-box .file-w {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
.file-tile-box .file-w .someFile {
  position: absolute;
  text-align: center;
  top: 24px;
  left: 0;
  z-index: 10;
  color: white;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  vertical-align: middle;
  text-transform: uppercase;
  width: 100%;
}
.file-tile-box .file-w img {
  width: 64px;
  height: auto;
  max-width: 100%;
}
.file-tile-box .file-w img.thumbnail {
  width: auto;
  height: auto;
}
.file-tile-box .file-name {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
.file-tile-box .file-name:hover .hoverName {
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 3px 3px 3px 3px;
  background: rgba(77, 166, 190, 0.2);
  color: #333;
  position: absolute;
  bottom: -30px;
  display: block;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 10px;
  z-index: 999999;
  font-weight: normal;
}
.file-tile-box.video-tile-box {
  width: 120px;
  padding: 0;
}
.file-tile-box.video-tile-box .file-w {
  border-radius: 6px 6px 0 0;
  height: 90px;
  width: 100%;
}
.file-tile-box.video-tile-box .file-w.bg-black {
  background-color: black;
}
.file-tile-box.video-tile-box .file-w img {
  width: 100%;
}
.file-tile-box.video-tile-box .file-w img.pornhub {
  width: 100px;
  margin-top: -5px;
}
.file-tile-box.video-tile-box .file-w img.xvideos {
  width: 100px;
  margin-top: 30px;
}
.file-tile-box.video-tile-box .file-w img.videoIcon {
  width: 64px;
  height: 64px;
  margin-top: 14px;
}
.file-tile-box.video-tile-box .file-w.uploaded-video .image,
.file-tile-box.video-tile-box .file-w.uploaded-video video {
  width: 120px !important;
  height: 68px !important;
  padding: 11px 0 !important;
  box-sizing: content-box;
  background-color: black;
}
.file-tile-box.video-tile-box .file-name {
  margin: 0 5px 5px 5px;
}
.file-tile-box .hoverName {
  display: none;
}
