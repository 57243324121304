.pricing h1 {
  text-transform: capitalize !important;
  color: #46bbdb !important;
  text-shadow: 1px 1px 1px rgba(37, 128, 219, 0.2);
  margin-bottom: 100px !important;
}
.pricing .pricingColumn {
  max-width: 1240px;
  margin: auto;
}
.pricing .pricingColumn input[type="image"] {
  outline: none;
}
.pricing .pricingColumn .plansWrap {
  justify-content: center;
}
.pricing .plan {
  text-align: center;
  min-height: 300px !important;
  -webkit-box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  width: 220px !important;
  margin: 0.875em 0.875em 4em 0.875em !important;
}
.pricing .plan div.popular {
  position: absolute;
  margin: -44px 45px;
  display: block;
  color: white !important;
  border-color: #2e89d3 !important;
  background-color: #2e89d3 !important;
  font-weight: 400;
  font-size: 16px;
  width: 130px;
}
.pricing .plan:hover {
  -webkit-box-shadow: 2px 2px 7px rgba(46, 137, 211, 0.5) !important;
  box-shadow: 2px 2px 7px rgba(46, 137, 211, 0.5) !important;
  border: 1px solid rgba(46, 137, 211, 0.5) !important;
}
.pricing .plan.disabled,
.pricing .plan[disabled] {
  background-color: rgba(40, 40, 40, 0.05) !important;
}
.pricing .plan.disabled *,
.pricing .plan[disabled] * {
  pointer-events: none;
  color: rgba(40, 40, 40, 0.3) !important;
}
.pricing .plan.disabled .buttons,
.pricing .plan[disabled] .buttons,
.pricing .plan.disabled button,
.pricing .plan[disabled] button {
  background-color: rgba(40, 40, 40, 0.05) !important;
}
.pricing .plan.disabled:hover,
.pricing .plan[disabled]:hover {
  -webkit-box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.pricing .meta {
  margin: 30px 0 50px 0;
}
.pricing .description {
  color: #2e89d3 !important;
  font-size: 1.2em;
}
.pricing .description strong {
  font-size: 3em;
  font-weight: 500;
}
.pricing .description div {
  line-height: 2em !important;
}
.pricing .paypalBanner {
  margin: auto;
}
