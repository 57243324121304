.contact h1 {
  text-transform: capitalize !important;
  color: #46bbdb !important;
  text-shadow: 1px 1px 1px rgba(37, 128, 219, 0.2);
}
.contact .form {
  border-radius: 0 0 0.28571429rem 0.28571429rem !important;
}
.contact .form .text-field {
  display: block !important;
  margin-bottom: 10px;
}
.contact .form .text-field input[type="text"],
.contact .form .text-field textarea {
  border: 0;
  background: transparent;
  padding: 6px 0 7px 0;
}
.contact .form input:-webkit-autofill {
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 0 0px 9999px white;
  box-shadow: inset 0 0 0px 9999px white;
}
.contact .form input:focus,
.contact .form input:-webkit-autofill:focus {
  border-color: #66afe9;
  -webkit-box-shadow: inset 0 0 0px 9999px white, 0 0 8px rgba(255, 255, 255, 0);
  box-shadow: inset 0 0 0px 9999px white, 0 0 8px rgba(255, 255, 255, 0);
}
.contact .button {
  display: block;
  margin: auto;
}
