.listLayoutTable th button {
  color: rgba(0, 0, 0, 0.87) !important;
}
.listLayoutTable .ui[class*="right labeled"].icon.button {
  background: transparent !important;
  padding-right: 2em !important;
  padding-left: 1em !important;
}
.listLayoutTable .icon {
  background: transparent !important;
}
.listLayoutTable .ui.table thead th {
  padding: 0 !important;
}
.listLayoutTable td * {
  vertical-align: middle !important;
}
.listLayoutTable .iconImage {
  max-height: 48px !important;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}
.listLayoutTable .iconNameWrap {
  display: inline-block;
}
.listLayoutTable .iconName {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  vertical-align: middle !important;
  max-width: 0;
}
.listLayoutTable .iconImage img {
  max-width: 48px !important;
}
.listLayoutTable .tinyCell {
  width: 64px !important;
}
.listLayoutTable .smallCell {
  width: 150px !important;
}
.listLayoutTable .iconCell {
  width: 64px !important;
}
.listLayoutTable tr:hover td {
  color: #2e89d3;
  cursor: pointer;
}
.listLayoutTable tr.active:hover td {
  color: white;
}
